import React, { useState } from 'react';
import { ArrowLeft, CheckCircle } from 'lucide-react';

const GrowthServiceForm = () => {
  const [showWaitlistConfirm, setShowWaitlistConfirm] = useState(false);
  
  const handleBackClick = () => {
    window.history.back();
  };

  return (
    
    <div className="min-h-screen bg-gradient-to-br from-slate-50 to-slate-100 p-6">
      <div className="max-w-6xl mx-auto">
        {/* Back Button */}
        <button 
          onClick={handleBackClick}
          className="mb-8 inline-flex items-center text-amber-600 hover:text-amber-700 font-medium"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Home
        </button>

        {/* Main Content */}
        <div className="bg-white rounded-xl shadow-lg border border-amber-200/20">
          <div className="grid md:grid-cols-2 gap-24 p-12">
            {/* Information Section */}
            <div className="space-y-8 pr-6">
              <div>
                <h1 className="text-2xl font-semibold text-slate-900 mb-6">Growth Partners for Scale-ups</h1>
                
                <div className="space-y-8">
                  <div>
                    <h2 className="text-lg font-medium text-slate-900 mb-4">Who We Are</h2>
                    <p className="text-slate-600 leading-relaxed">
                      We are a team of seasoned growth experts with a proven track record of scaling companies from startup to $1B+ in value. Our specialty lies in building robust growth infrastructures, driving high-impact performance marketing campaigns, and implementing advanced SEO strategies. 
                    </p>
                  </div>

                  <div>
                    <p className="text-slate-600 leading-relaxed">
                      Ready to take your business to the next level? Book a call today to learn more about how we can help you achieve extraordinary growth.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Form Section */}
            <div className="pl-6 border-l border-slate-100">
              <form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
                <div>
                  <label className="block text-sm font-medium text-slate-900 mb-1">
                    Name
                  </label>
                  <input
                    type="text"
                    className="w-full p-3 bg-slate-50 border border-slate-200 rounded-lg focus:ring-2 focus:ring-amber-100 focus:border-amber-400 outline-none"
                    placeholder="Enter your name"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-slate-900 mb-1">
                    Surname
                  </label>
                  <input
                    type="text2"
                    className="w-full p-3 bg-slate-50 border border-slate-200 rounded-lg focus:ring-2 focus:ring-amber-100 focus:border-amber-400 outline-none"
                    placeholder="Enter your surname"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-slate-900 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    className="w-full p-3 bg-slate-50 border border-slate-200 rounded-lg focus:ring-2 focus:ring-amber-100 focus:border-amber-400 outline-none"
                    placeholder="your@email.com"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-slate-900 mb-1">
                    Company Size
                  </label>
                  <select
                    className="w-full p-3 bg-slate-50 border border-slate-200 rounded-lg focus:ring-2 focus:ring-amber-100 focus:border-amber-400 outline-none appearance-none bg-[url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'https://www.w3.org/2000/svg\' width=\'16\' height=\'16\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'%23475569\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'%3E%3Cpath d=\'m6 9 6 6 6-6\'/%3E%3C/svg%3E')] bg-no-repeat bg-[center_right_1rem]"
                  >
                    <option value="" disabled selected>Select company size</option>
                    <option value="1-10">1-10 employees</option>
                    <option value="10-30">10-30 employees</option>
                    <option value="50-100">50-100 employees</option>
                    <option value="100-500">100-500 employees</option>
                    <option value="500+">500+ employees</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-slate-900 mb-1">
                    Message
                  </label>
                  <textarea
                    className="w-full p-3 bg-slate-50 border border-slate-200 rounded-lg focus:ring-2 focus:ring-amber-100 focus:border-amber-400 outline-none h-32 resize-none"
                    placeholder="Tell us how we can help you..."
                  />
                </div>


                <button
                  type="button"
                  onClick={async () => {
                    const formData = {
                      Name: document.querySelector('input[type="text"]').value,
                      Surname: document.querySelector('input[type="text2"]').value,
                      Email: document.querySelector('input[type="email"]').value,
                      CompanySize: document.querySelector('select').value,
                      Message: document.querySelector('textarea').value,
                    };
                    try {
                      const response = await fetch('https://www.getu.ai/api/front_end/interfaces/homepage/book_a_call', {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(formData),
                      });
                      if (!response.ok) {
                        console.error('请求失败');
                      }
                    } catch (error) {
                      console.error('请求出错:', error);
                    } finally {
                      setShowWaitlistConfirm(true);
                    }
                  }}
                  className="w-full bg-gradient-to-r from-amber-500 to-amber-600 text-white py-3 px-4 rounded-lg hover:from-amber-600 hover:to-amber-700 transition-all font-medium"
                >
                  Join Wait List
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* 等待列表确认弹窗 */}
        {showWaitlistConfirm && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 text-center">
              <div className="flex flex-col items-center gap-4">
                <CheckCircle className="w-12 h-12 text-green-500" />
                <h3 className="text-xl font-semibold text-slate-900">Thank You!</h3>
                <p className="text-slate-600">
                  You have successfully joined our waitlist. Our experts will contact you soon.
                </p>
                <button
                  onClick={() => {
                    setShowWaitlistConfirm(false);
                    window.location.href = '/';
                  }}
                  className="mt-4 px-6 py-2 bg-amber-500 text-white rounded-lg font-medium hover:bg-amber-600 transition-colors"
                >
                  Got it
                </button>
              </div>
            </div>
          </div>
        )}

        <footer className="mt-12 text-center text-sm text-slate-500">
          <p>© 2024 GETU - Growth Solutions. All rights reserved.</p>
          <div className="mt-2">
            <a href="/privacy" className="hover:text-amber-600">Privacy Policy</a>
            <span className="mx-2">|</span>
            <a href="/terms" className="hover:text-amber-600">Terms of Service</a>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default GrowthServiceForm;